import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {SnackbarService} from "./snackbar.service";
import {AsyncPipe, NgIf} from "@angular/common";

@Component({
  selector: 'app-snackbar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf
  ],
  templateUrl: './snackbar.component.html',
  styleUrl: './snackbar.component.css'
})
export class SnackbarComponent {
  service = inject(SnackbarService)
}
