<div  *ngIf="service.isOpen | async">
  <div class="container position-relative pointer" (click)="service.setOpen(false)">
    <div class="alert" [style.border-left-color]="service.param.type === 'success' ? 'var(--green)' : 'var(--red-color)'">
      <div class="row align-items-center">
        <div class="col-2 col-md-1">
          <img src="assets/icon/{{service.param.btnIcon}}" class="bg-light-gray p-2 border-radius" />
        </div>
        <div class="col-10 col-md-9">
          <h1 class="modal-title fs-5 fw-bold">{{service.param.title}}</h1>
          <p class="mb-0">{{service.param.text}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
