import {HttpErrorResponse, HttpEvent, HttpEventType, HttpHandlerFn, HttpRequest} from '@angular/common/http';
import {catchError, Observable, tap, throwError} from "rxjs";
import {inject} from "@angular/core";
import {SnackBarParam, SnackbarService} from "../components/snackbar/snackbar.service";
import {JsonReply} from "../models/JsonReply";

export function errorHttpInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const snackbar = inject(SnackbarService)
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      console.log(error);
      const param =  new SnackBarParam(
        'Error',
        error.message,
        'error'
      );
      handleAuthError(param, snackbar)
      return throwError(() => new Error('An error occurred.'));
    }),
    tap(event => {
      if(event.type === HttpEventType.Response){
        if(event.body && (event.body as JsonReply<any>).ok === false) {
          const param =  new SnackBarParam(
            'Error',
            'Error to ' + (event.body as JsonReply<any>).errorMessage,
            'error'
          );
          handleAuthError(param, snackbar)
        }
      }
    })
  )
}

function handleAuthError(param: SnackBarParam, snackbar: SnackbarService) {
  snackbar.alert(param);
}


